/* src/App.css */
.app {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.map-container {
  flex-grow: 1;
  height: 100vh;
  width: 100vw;
  position: relative;
  overflow: hidden;
}

.map-marker {
  background-color: red;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.MuiCollapse-wrapperInner {
  overflow: scroll;
  width: 100% !important;
}

::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-width: 600px) {
  .sidebar-container {
    width: 100vw;
    height: 100vh;
    min-width: 0;
  }

  .map-container {
    width: 100vw;
    height: 100vh;
    position: absolute;
  }
}
